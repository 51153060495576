export type Language = 'en' | 'ja' | 'ko';

export interface Translations {
  [id: string]: string;
}

export interface I18nMessages {
  en: Translations;
  ja: Translations;
  ko: Translations;
}

const i18nTranslations: I18nMessages = {
  "en": {
    "footer.linkTerms.text": "Terms of Use",
    "footer.linkPrivacyPolicy.text": "Privacy Policy",
    "footer.linkCookiePolicy.text": "Cookie Policy",
    "footer.linkSecurity.text": "Security",
    "footer.copyright": "Copyright © {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "signup.header.linkText1": "Already have an account?",
    "signup.header.linkText2": " Log in",
    "signup.hero.header": "The best CI/CD platform. Anywhere. For free.",
    "signup.hero.paragraph": "Get the most for your minute on CircleCI. Start with up to 6,000 free build minutes per month. CircleCI is the fastest CI/CD platform available, with the most options for compute and execution environments.",
    "signup.hero.titleBtns": "Start building",
    "signup.hero.primaryBtn": "Sign up with GitHub",
    "signup.hero.primaryBtnDropdown1": "All Repos",
    "signup.hero.primaryBtnDropdown2": "Public Repos Only",
    "signup.hero.secondaryBtn": "Sign up with Bitbucket",
    "signup.hero.emailBtn": "Sign up with Email",
    "signup.hero.gitlabBtn": "Sign up with GitLab",
    "signup.hero.signupBtn": "Sign Up",
    "signup.hero.emailLabel": "Email",
    "signup.hero.emailInstructions": "Enter your email. Example: name@domain.com",
    "signup.hero.passwordLabel": "Password",
    "signup.hero.passwordInstructions": "Use at least 10 characters, with 3 of the following: number, special character (!@#$%^&*), upper or lower case letter",
    "signup.hero.passwordErrorMsg": "Enter 10 or more characters. Include at least 3 of the following: number (0-9), special character (!@#$%^&*), upper case letter (A-Z), or lower case letter (a-z).",
    "signup.hero.dividerText": "or",
    "signup.hero.ff_vcsSubheading": "Works with GitHub, GitLab, and Bitbucket",
    "vcsAuthorize.header.linkText1": "Don't have an account?",
    "vcsAuthorize.header.linkText2": " Sign up",
    "error.title": "We’re sorry, something went wrong.",
    "error.content": "Please refresh and try again.",
    "signup-stackoverflow.hero.header": "Sign up now",
    "signup-stackoverflow.hero.subheader": "Welcome, Stack Overflow Listeners!",
    "signup-stackoverflow.hero.paragraph": "Start building on our free plan and get access to unlimited projects on our fully-hosted cloud platform.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "Start building",
    "signup-stackoverflow.hero.primaryBtn": "Sign up with GitHub",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "All Repos",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "Public Repos Only",
    "signup-stackoverflow.hero.secondaryBtn": "Sign up with Bitbucket",
    "signup-stackoverflow.hero.emailBtn": "Sign up with Email",
    "signup-stackoverflow.hero.gitlabBtn": "Sign up with GitLab",
    "vcsAuthorized.hero.alert.sessionExpired.title": "Your session has expired.",
    "vcsAuthorized.hero.alert.sessionExpired.content": "Please log back in.",
    "vcsAuthorized.hero.alert.emailVerified.title": "Email verified.",
    "vcsAuthorized.hero.alert.emailVerified.content": "Your email is verified. You can continue using the application.",
    "vcsAuthorized.hero.header": "Build, test and deploy with speed and confidence",
    "vcsAuthorized.hero.paragraph": "You'll be taken to GitHub or Bitbucket to authenticate.",
    "vcsAuthorized.hero.signUpTitle": "Don't have an account?",
    "vcsAuthorized.hero.signUpBtn": "Sign Up",
    "vcsAuthorized.hero.logInBtn": "Log in",
    "vcsAuthorized.hero.titleBtns": "Log in to CircleCI",
    "vcsAuthorized.hero.welcomeTitleBtn": "Welcome back to CircleCI!",
    "vcsAuthorized.hero.primaryBtn": "Log in with GitHub",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "Signed up for CircleCI with GitHub or ",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "Bitbucket before September 2023?",
    "vcsAuthorized.hero.primaryBtnDropdown1": "All Repos",
    "vcsAuthorized.hero.primaryBtnDropdown2": "Public Repos Only",
    "vcsAuthorized.hero.secondaryBtn": "Log in with Bitbucket",
    "vcsAuthorized.hero.emailBtn": "Log in with Email",
    "vcsAuthorized-enterprise.hero.header": "Log in to CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI's continuous integration and deployment platform helps software teams rapidly ship code they trust by automating the build, test, and deploy process.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Log In With GitHub",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "By logging in or signing up, you are agreeing to our",
    "disclaimer.part1.vcsAuthorized": "By logging in or signing up, you are agreeing to our",
    "disclaimer.link1Text": "SaaS Agreement",
    "disclaimer.part2": "and",
    "disclaimer.link2Text": "Privacy Policy",
    "disclaimer.part3": ". We ask for read/write access to make your experience seamless on CircleCI. If you are a GitHub user and aren’t ready to share access to your private projects, you can choose public repos instead.",
    "disclaimer.part3Shortened": ".",
    "ff_disclaimer.part1.signup": "By signing up, you are agreeing to our",
    "ff_disclaimer.link1Text": "SaaS Agreement",
    "ff_disclaimer.part2": "and",
    "ff_disclaimer.link2Text": "Privacy Policy",
    "recaptcha.part1": " Protected by reCAPTCHA, Google ",
    "recaptcha.privacyLinkText": "Privacy Policy",
    "recaptcha.part2": " and ",
    "recaptcha.termsLinkText": "Terms of Service",
    "recaptcha.part3": " apply.",
    "siteMap.column1.heading": "Product",
    "siteMap.column2.heading": "Support",
    "siteMap.column3.heading": "Resources",
    "siteMap.column4.heading": "Company",
    "siteMap.column5.heading": "Getting Started",
    "siteMap.linkOverview.text": "Overview",
    "siteMap.linkPricing.text": "Pricing",
    "siteMap.linkIntegrations.text": "Integrations",
    "siteMap.linkOpenSource.text": "Open Source",
    "siteMap.linkEnterprise.text": "Enterprise",
    "siteMap.linkChangeLog.text": "Changelog",
    "siteMap.linkDocumentation.text": "Documentation",
    "siteMap.linkSupport.text": "Get Support",
    "siteMap.linkForum.text": "Community Forum",
    "siteMap.linkStatus.text": "System Status",
    "siteMap.linkRequests.text": "Feature Requests",
    "siteMap.linkPremium.text": "Premium Support",
    "siteMap.linkBlog.text": "Blog",
    "siteMap.linkCaseStudies.text": "Case Studies",
    "siteMap.linkEbooks.text": "Ebooks",
    "siteMap.linkReports.text": "Reports",
    "siteMap.linkWebinars.text": "Webinars",
    "siteMap.linkVideos.text": "Videos",
    "siteMap.linkAbout.text": "About Us",
    "siteMap.linkCareers.text": "Careers ",
    "siteMap.linkCareers.subText": "(We're hiring!)",
    "siteMap.linkTeam.text": "Team",
    "siteMap.linkContact.text": "Contact Us",
    "siteMap.linkPartner.text": "Partner With Us",
    "siteMap.linkPress.text": "Newsroom",
    "siteMap.linkCI.text": "What is CI?",
    "siteMap.linkGetStarted.text": "How to get started on CircleCI",
    "trustedBy": "Trusted by more than 30,000 organizations",
    "links.internal.signup": "/signup/",
    "links.internal.vcsAuthorize": "/vcs-authorize/",
    "links.external.root": "https://circleci.com/",
    "links.external.product": "https://circleci.com/product/",
    "links.external.pricing": "https://circleci.com/pricing/",
    "links.external.integrations": "https://circleci.com/integrations/",
    "links.external.openSource": "https://circleci.com/open-source/",
    "links.external.enterprise": "https://circleci.com/enterprise/",
    "links.external.changeLog": "https://circleci.com/changelog/",
    "links.external.docs": "https://circleci.com/docs/",
    "links.external.support": "https://support.circleci.com/hc/en-us/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/customers/",
    "links.external.ebooks": "https://circleci.com/resources/#ebooks",
    "links.external.reports": "https://circleci.com/resources/#reports",
    "links.external.webinars": "https://circleci.com/resources/webinars/",
    "links.external.videos": "https://circleci.com/resources/#videos",
    "links.external.about": "https://circleci.com/about/",
    "links.external.careers": "https://circleci.com/careers/",
    "links.external.team": "https://circleci.com/about/team/",
    "links.external.contact": "https://circleci.com/contact/",
    "links.external.partners": "https://circleci.com/partners/",
    "links.external.newsroom": "https://circleci.com/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/circleci",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/security/",
    "links.external.login": "https://circleci.com/github-login/",
    "links.external.loginBitBucket": "https://circleci.com/bitbucket-login/",
    "links.external.authSignUp": "https://circleci.com/auth/signup/",
    "links.external.authLogin": "https://circleci.com/auth/login/",
    "links.external.loginPublic": "https://circleci.com/login-public/",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=en",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=en",
    "vcsAuthorizePage.title": "Log in - CircleCI",
    "vcsAuthorizePage.metaTitle": "Log in to access CircleCI",
    "vcsAuthorizePage.metaDescription": "Log into your CircleCI account to view the status of your builds. If you don’t have an account, sign up at https://circleci.com/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Log into your CircleCI account",
    "signupPage.title": "Signup - CircleCI",
    "signupPage.metaTitle": "Start building CI/CD pipelines for free today",
    "signupPage.metaDescription": "Sign up for CircleCI and get access to 6,000 free build minutes per month, forever. Connect CircleCI to your VCS on GitHub, GitLab, or Bitbucket, and build on Linux, macOS, Windows, Android, GPU, and Docker.",
    "signupPage.canonicalLink": "https://circleci.com/signup/"
  },
  "ja": {
    "footer.linkTerms.text": "利用規約",
    "footer.linkPrivacyPolicy.text": "プライバシーポリシー",
    "footer.linkCookiePolicy.text": "クッキーポリシー",
    "footer.linkSecurity.text": "セキュリティポリシー",
    "footer.copyright": "Copyright © {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "vcsAuthorize.header.linkText1": "アカウントをお持ちでない方は",
    "vcsAuthorize.header.linkText2": " こちら",
    "signup.header.linkText1": "既にアカウントをお持ちの方は",
    "signup.header.linkText2": " こちら",
    "signup.hero.header": "ユーザー登録",
    "signup.hero.paragraph": "大規模なプロジェクトも扱える無料のプランから始めることができます。GitHub で個人リポジトリをお使いでない方は、プルダウンから「公開リポジトリと連携」を選んでユーザー登録に進んでください。",
    "signup.hero.titleBtns": "新規アカウントを作成する",
    "signup.hero.primaryBtn": "GitHub で登録",
    "signup.hero.primaryBtnDropdown1": "全てのリポジトリと連携",
    "signup.hero.primaryBtnDropdown2": "公開リポジトリと連携",
    "signup.hero.secondaryBtn": "Bitbucket で登録",
    "signup.hero.emailBtn": "メールで登録",
    "signup.hero.signupBtn": "無料でサインアップ",
    "signup.hero.gitlabBtn": "GitLab で登録",
    "signup.hero.emailLabel": "メールアドレス",
    "signup.hero.emailInstructions": "メールアドレスを記入して下さい。例: name@domain",
    "signup.hero.passwordLabel": "パスワード",
    "signup.hero.passwordInstructions": "10文字以上で入力して下さい。半角の数字、記号 (!@#$%^&*)、英大文字および小文字の中から3種類以上を組み合わせて下さい。",
    "signup.hero.passwordErrorMsg": "10文字以上で入力して下さい。半角の英小文字(a-z)と大文字(A-Z)、数字(0-9)、記号(!@#$%^&*)の中から3種類以上を組み合わせて下さい。",
    "signup.hero.dividerText": "または",
    "signup.hero.ff_vcsSubheading": "GitHub、GitLab、Bitbucket に対応",
    "error.title": "申し訳ありません。問題が発生しました。",
    "error.content": "ページを更新して、やり直してください。",
    "signup-stackoverflow.hero.header": "ユーザー登録",
    "signup-stackoverflow.hero.subheader": "Welcome, Stack Overflow Listeners!",
    "signup-stackoverflow.hero.paragraph": "大規模なプロジェクトも扱える無料のプランから始めることができます。GitHub で個人リポジトリをお使いでない方は、プルダウンから「公開リポジトリと連携」を選んでユーザー登録に進んでください。",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "新規アカウントを作成する",
    "signup-stackoverflow.hero.primaryBtn": "GitHub でログイン",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "全てのリポジトリと連携",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "公開リポジトリと連携",
    "signup-stackoverflow.hero.secondaryBtn": "Bitbucket でログイン",
    "signup-stackoverflow.hero.emailBtn": "メールでログイン",
    "signup-stackoverflow.hero.gitlabBtn": "GitLab で登録",
    "vcsAuthorized.hero.alert.sessionExpired.title": "セッションの有効期限が切れています。",
    "vcsAuthorized.hero.alert.sessionExpired.content": "ログインし直してください。",
    "vcsAuthorized.hero.alert.emailVerified.title": "メールアドレス確認完了。",
    "vcsAuthorized.hero.alert.emailVerified.content": "メールアドレスの確認が完了しました。 引き続き CircleCI をお楽しみください。",
    "vcsAuthorized.hero.header": "素早く確実にビルド、テスト、デプロイ",
    "vcsAuthorized.hero.paragraph": "CircleCI の利用には GitHub もしくは Bitbucket のアカウントが必要です。",
    "vcsAuthorized.hero.signUpTitle": "アカウントをお持ちでない場合",
    "vcsAuthorized.hero.signUpBtn": "登録",
    "vcsAuthorized.hero.logInBtn": "ログイン",
    "vcsAuthorized.hero.titleBtns": "アカウントをお持ちの場合",
    "vcsAuthorized.hero.welcomeTitleBtn": "CircleCI にお帰りなさい！",
    "vcsAuthorized.hero.primaryBtn": "GitHub でログイン",
    "vcsAuthorized.hero.legacySignUpHelperPt1": "2023 年 9 月以前に GitHub または Bitbucket",
    "vcsAuthorized.hero.legacySignUpHelperPt2": "で CircleCI に登録済みの方はこちら",
    "vcsAuthorized.hero.legacySignUpHelperDate": "で登録済みの方はこちら",
    "vcsAuthorized.hero.primaryBtnDropdown1": "全てのリポジトリと連携",
    "vcsAuthorized.hero.primaryBtnDropdown2": "公開リポジトリと連携",
    "vcsAuthorized.hero.secondaryBtn": "Bitbucket でログイン",
    "vcsAuthorized.hero.emailBtn": "メールでログイン",
    "vcsAuthorized-enterprise.hero.header": "Log in to CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI's continuous integration and deployment platform helps software teams rapidly ship code they trust by automating the build, test, and deploy process.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Log In With GitHub",
    "twitter.screenName": "CircleCIJapan",
    "twitter.paragraph": "CircleCI は現在英語版のみ用意しています。今後の日本語化の予定や日本語でのサポートについては、CircleCI の公式 Twitter アカウントをチェックしてください。",
    "twitter.firstLine": "CircleCI は現在英語版のみ用意しています。",
    "disclaimer.part1.signup": "ユーザー登録すると、",
    "disclaimer.part1.vcsAuthorized": "ログインまたはユーザー登録を行うことで, ",
    "disclaimer.link1Text": "SaaS の利用規約",
    "disclaimer.part2": "および",
    "disclaimer.link2Text": "プライバシーポリシー",
    "disclaimer.part3": "に同意したものと見なされます。",
    "disclaimer.part3Shortened": " の内容に同意したものとみなされます。",
    "ff_disclaimer.part1.signup": "ユーザー登録すると、",
    "ff_disclaimer.link1Text": "SaaS 契約 (英語)",
    "ff_disclaimer.part2": "と",
    "ff_disclaimer.link2Text": "プライバシーポリシー (英語)",
    "ff_disclaimer.part3": " の内容に同意したものとみなされます。場合によっては、CircleCI のサービス改善を目的として、お客様のアカウントデーターへの読み取りアクセスまたは書き込みアクセスの許可をお願いすることがあります。",
    "recaptcha.part1": "reCAPTCHA による確認が必要です。Google の",
    "recaptcha.privacyLinkText": "プライバシーポリシー",
    "recaptcha.part2": "および",
    "recaptcha.termsLinkText": "利用規約",
    "recaptcha.part3": "が適用されます。",
    "ff_recaptcha.part1": "reCAPTCHA により確認が必要です。Google プライバシーポリシーおよび",
    "ff_recaptcha.termsLinkText": "利用規約",
    "ff_recaptcha.part2": "が適用されます。",
    "siteMap.column1.heading": "プロダクト",
    "siteMap.column2.heading": "サポート",
    "siteMap.column3.heading": "リソース",
    "siteMap.column4.heading": "企業情報",
    "siteMap.column5.heading": "はじめに",
    "siteMap.linkOverview.text": "製品概要",
    "siteMap.linkPricing.text": "料金プラン",
    "siteMap.linkIntegrations.text": "連携ツール",
    "siteMap.linkOpenSource.text": "オープンソース",
    "siteMap.linkEnterprise.text": "エンタープライズ",
    "siteMap.linkChangeLog.text": "更新履歴",
    "siteMap.linkDocumentation.text": "ドキュメント",
    "siteMap.linkSupport.text": "ヘルプ",
    "siteMap.linkForum.text": "公式コミュニティ",
    "siteMap.linkStatus.text": "サービス稼働状況",
    "siteMap.linkRequests.text": "改善要望",
    "siteMap.linkPremium.text": "有償サポート",
    "siteMap.linkBlog.text": "ブログ",
    "siteMap.linkCaseStudies.text": "事例紹介",
    "siteMap.linkEbooks.text": "eBook",
    "siteMap.linkReports.text": "レポート",
    "siteMap.linkWebinars.text": "オンライン講座",
    "siteMap.linkVideos.text": "動画解説",
    "siteMap.linkAbout.text": "会社概要",
    "siteMap.linkCareers.text": "採用 ",
    "siteMap.linkCareers.subText": "(募集中)",
    "siteMap.linkTeam.text": "チームスタッフ",
    "siteMap.linkContact.text": "お問い合わせ",
    "siteMap.linkPartner.text": "パートナー",
    "siteMap.linkPress.text": "メディア掲載",
    "siteMap.linkCI.text": "CI とは？",
    "siteMap.linkGetStarted.text": "CircleCI の導入手順",
    "trustedBy": "世界3万以上の企業が信頼する CI/CDツール",
    "links.internal.signup": "/ja/signup/",
    "links.internal.vcsAuthorize": "/ja/vcs-authorize/",
    "links.external.root": "https://circleci.com/ja/",
    "links.external.product": "https://circleci.com/ja/product/",
    "links.external.pricing": "https://circleci.com/ja/pricing/",
    "links.external.integrations": "https://circleci.com/ja/integrations/",
    "links.external.openSource": "https://circleci.com/ja/open-source/",
    "links.external.enterprise": "https://circleci.com/ja/enterprise/",
    "links.external.changeLog": "https://circleci.com/ja/changelog/",
    "links.external.docs": "https://circleci.com/docs/ja/",
    "links.external.support": "https://support.circleci.com/hc/ja/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/ja/support/plans/",
    "links.external.blog": "https://circleci.com/ja/blog/",
    "links.external.customers": "https://circleci.com/ja/customers/",
    "links.external.ebooks": "https://circleci.com/ja/resources/#ebooks",
    "links.external.reports": "https://circleci.com/ja/resources/#reports",
    "links.external.webinars": "https://circleci.com/ja/resources/webinars/",
    "links.external.videos": "https://circleci.com/ja/resources/#videos",
    "links.external.about": "https://circleci.com/ja/about/",
    "links.external.careers": "https://circleci.com/ja/careers/",
    "links.external.team": "https://circleci.com/ja/about/team/",
    "links.external.contact": "https://circleci.com/ja/contact/",
    "links.external.partners": "https://circleci.com/ja/partners/",
    "links.external.newsroom": "https://circleci.com/ja/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/ja/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/ja/2.0/",
    "links.social.blogFeed": "https://circleci.com/ja/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/CircleCIJapan",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/ja/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/ja/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/ja/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/ja/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/ja/security/",
    "links.external.login": "https://circleci.com/github-login/",
    "links.external.loginBitBucket": "https://circleci.com/bitbucket-login/",
    "links.external.authSignUp": "https://circleci.com/auth/signup/",
    "links.external.authLogin": "https://circleci.com/auth/login/",
    "links.external.loginPublic": "https://circleci.com/login-public/",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=ja",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=ja",
    "vcsAuthorizePage.title": "ログイン - CircleCI",
    "vcsAuthorizePage.metaTitle": "ログイン",
    "vcsAuthorizePage.metaDescription": "CircleCIへログイン。アカウントをお持ちでない場合は https://circleci.com/signup/ からサインアップ。",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Log into your CircleCI account",
    "signupPage.title": "サインアップ - CircleCI",
    "signupPage.metaTitle": "サインアップ",
    "signupPage.metaDescription": "CircleCIに登録。無料でパブリック、プライベートなリポジトリのビルドをはじめる。GitHub、Bitbucketに連携してLinux、macOS、 Windows、Android、Dockerをビルド。",
    "signupPage.canonicalLink": "https://circleci.com/ja/signup/"
  },
  "ko": {
    "footer.linkTerms.text": "이용 약관",
    "footer.linkPrivacyPolicy.text": "개인 정보 보호 정책",
    "footer.linkCookiePolicy.text": "쿠키 정책",
    "footer.linkSecurity.text": "보안",
    "footer.copyright": "Copyright © {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "footer.copyrightEnterprise": "© {currentYear} Circle Internet Services, Inc., All Rights Reserved.",
    "signup.header.linkText1": "이미 계정이 있으십니까?",
    "signup.header.linkText2": " 로그 인",
    "signup.hero.header": "최고의 CI/CD 플랫폼. 어﻿디﻿서﻿나. 무﻿료.",
    "signup.hero.paragraph": "CircleCI에서 여러분의 시간을 최대한 활용하세요. 매월 최대 6,000 분의 무료 빌드 시간으로 시작하세요. CircleCI는 가장 빠른 CI/CD 플랫폼으로, 컴퓨팅 및 실행 환경의 가장 많은 옵션을 제공합니다.",
    "signup.hero.titleBtns": "구축 시작",
    "signup.hero.primaryBtn": "GitHub으로 가입",
    "signup.hero.primaryBtnDropdown1": "모든 리포지토리",
    "signup.hero.primaryBtnDropdown2": "공개 리포지토리만",
    "signup.hero.secondaryBtn": "Bitbucket으로 가입",
    "signup.hero.emailBtn": "이메일로 가입",
    "signup.hero.signupBtn": "가입",
    "signup.hero.gitlabBtn": "GitLab으로 가입",
    "signup.hero.emailLabel": "이메일",
    "signup.hero.emailInstructions": "이메일을 입력하세요. 예: name@domain.com",
    "signup.hero.passwordLabel": "비밀번호",
    "signup.hero.passwordInstructions": "10자 이상을 입력하세요. 숫자(0-9), 특수 문자(!@#$%^&*), 대문자(A-Z) 또는 소문자(a-z) 중 3개 이상을 포함하세요.",
    "signup.hero.passwordErrorMsg": "10자 이상을 입력하세요. 숫자(0-9), 특수 문자(!@#$%^&*), 대문자(A-Z) 또는 소문자(a-z) 중 3개 이상을 포함하세요.",
    "signup.hero.dividerText": "또는",
    "signup.hero.videoId": "uD6iNU-SESE",
    "signup.hero.ff_vcsSubheading": "GitHub, GitLab, Bitbucket과 함께 사용 가능",
    "vcsAuthorize.header.linkText1": "계정이 없으신가요?",
    "vcsAuthorize.header.linkText2": " 가입",
    "error.title": "죄송합니다. 문제가 발생했습니다.",
    "error.content": "새로고침하고 다시 시도하세요.",
    "signup-stackoverflow.hero.header": "최고의 CI/CD 플랫폼. 어﻿디﻿서﻿나. 무﻿료.",
    "signup-stackoverflow.hero.subheader": "Welcome, Stack Overflow Listeners!",
    "signup-stackoverflow.hero.paragraph": "CircleCI에서 여러분의 시간을 최대한 활용하세요. 매월 최대 6,000 분의 무료 빌드 시간으로 시작하세요. CircleCI는 가장 빠른 CI/CD 플랫폼으로, 컴퓨팅 및 실행 환경의 가장 많은 옵션을 제공합니다.",
    "signup-stackoverflow.hero.videoId": "uD6iNU-SESE",
    "signup-stackoverflow.hero.titleBtns": "구축 시작",
    "signup-stackoverflow.hero.primaryBtn": "GitHub으로 가입",
    "signup-stackoverflow.hero.primaryBtnDropdown1": "모든 리포지토리",
    "signup-stackoverflow.hero.primaryBtnDropdown2": "공개 리포지토리만",
    "signup-stackoverflow.hero.secondaryBtn": "Bitbucket으로 가입",
    "signup-stackoverflow.hero.emailBtn": "이메일로 가입",
    "signup-stackoverflow.hero.gitlabBtn": "GitLab으로 가입",
    "vcsAuthorized.hero.alert.sessionExpired.title": "기간이 만료되었습니다.",
    "vcsAuthorized.hero.alert.sessionExpired.content": " 다시 로그인하십시오.",
    "vcsAuthorized.hero.alert.emailVerified.title": "이메일 확인됨.",
    "vcsAuthorized.hero.alert.emailVerified.content": "이메일이 확인되었습니다. 응용 프로그램을 계속 사용할 수 있습니다.",
    "vcsAuthorized.hero.header": "CircleCI에 로그인",
    "vcsAuthorized.hero.paragraph": "인증을 위해 GitHub 또는 Bitbucket으로 이동합니다.",
    "vcsAuthorized.hero.titleBtns": "구축 시작",
    "vcsAuthorized.hero.primaryBtn": "GitHub으로 로그인",
    "vcsAuthorized.hero.primaryBtnDropdown1": "모든 리포지토리",
    "vcsAuthorized.hero.primaryBtnDropdown2": "공개 리포지토리만",
    "vcsAuthorized.hero.secondaryBtn": "Bitbucket으로 로그인",
    "vcsAuthorized.hero.emailBtn": "이메일로 로그인",
    "vcsAuthorized.hero.videoId": "uD6iNU-SESE",
    "vcsAuthorized-enterprise.hero.header": "Log in to CircleCI server",
    "vcsAuthorized-enterprise.hero.paragraph": "CircleCI's continuous integration and deployment platform helps software teams rapidly ship code they trust by automating the build, test, and deploy process.",
    "vcsAuthorized-enterprise.hero.primaryBtn": "Log In With GitHub",
    "twitter.screenName": " ",
    "twitter.paragraph": " ",
    "disclaimer.part1.signup": "가입하시면",
    "disclaimer.part1.vcsAuthorized": "로그인하시면",
    "disclaimer.link1Text": "SaaS 계약",
    "disclaimer.part2": "및",
    "disclaimer.link2Text": "개인 정보 보호 정책",
    "disclaimer.part3": "에 동의하시는 것입니다. CircleCI에서 원활한 사용자 경험을 위해 읽기/쓰기 액세스 권한을 요청합니다. GitHub 사용자이지만 개인 프로젝트에 대한 액세스 권한을 공유하실 준비가 되지 않은 경우, 대신 공개 리포지토리를 선택하실 수 있습니다.",
    "disclaimer.part3Shortened": "에 동의하시는 것입니다.",
    "ff_disclaimer.section1.part1": "가입 시, 귀하는 당사의",
    "ff_disclaimer.section1.link1Text": " SaaS 계약 ",
    "ff_disclaimer.section1.part2": "및",
    "ff_disclaimer.section1.link2Text": " 개인정보 보호정책",
    "ff_disclaimer.section1.part3": "에 동의를 하게 됩니다.",
    "ff_disclaimer.section2.part1": "원활한 CircleCI 사용을 위해 경우에 따라 읽기/쓰기 접근 권한을 요청할 수 있습니다.",
    "ff_disclaimer.section3.part1": "구글의",
    "ff_disclaimer.section3.link1Text": " 개인정보 보호정책 ",
    "ff_disclaimer.section3.part2": "및",
    "ff_disclaimer.section3.link2Text": " 서비스 약관",
    "ff_disclaimer.section3.part3": "이 적용되며 리캡챠로 보호가 됩니다.",
    "recaptcha.part1": " reCAPTCHA로 보호되며 Google ",
    "recaptcha.privacyLinkText": "개인 정보 보호",
    "recaptcha.part2": " 및 ",
    "recaptcha.termsLinkText": " 서비스 약관이",
    "recaptcha.part3": " 적용됩니다.",
    "trustedBy": " ",
    "links.internal.signup": "/ko/signup/",
    "links.internal.vcsAuthorize": "/ko/vcs-authorize/",
    "links.external.root": "https://circleci.com/ko/",
    "links.external.product": "https://circleci.com/ko/product/",
    "links.external.pricing": "https://circleci.com/ko/pricing/",
    "links.external.integrations": "https://circleci.com/ko/integrations/",
    "links.external.openSource": "https://circleci.com/ko/open-source/",
    "links.external.enterprise": "https://circleci.com/ko/enterprise/",
    "links.external.changeLog": "https://circleci.com/ko/changelog/",
    "links.external.docs": "https://circleci.com/docs/ko/",
    "links.external.support": "https://support.circleci.com/hc/ko/",
    "links.external.forum": "https://discuss.circleci.com/",
    "links.external.systemStatus": "https://status.circleci.com/",
    "links.external.featureRequests": "https://ideas.circleci.com/",
    "links.external.premiumSupport": "https://circleci.com/ko/support/plans/",
    "links.external.blog": "https://circleci.com/blog/",
    "links.external.customers": "https://circleci.com/ko/customers/",
    "links.external.ebooks": "https://circleci.com/ko/resources/#ebooks",
    "links.external.reports": "https://circleci.com/ko/resources/#reports",
    "links.external.webinars": "https://circleci.com/ko/resources/webinars/",
    "links.external.videos": "https://circleci.com/ko/resources/#videos",
    "links.external.about": "https://circleci.com/ko/about/",
    "links.external.careers": "https://circleci.com/ko/careers/",
    "links.external.team": "https://circleci.com/ko/about/team/",
    "links.external.contact": "https://circleci.com/ko/contact/",
    "links.external.partners": "https://circleci.com/ko/partners/",
    "links.external.newsroom": "https://circleci.com/ko/newsroom/",
    "links.external.continuousIntegration": "https://circleci.com/ko/continuous-integration/",
    "links.external.docs2": "https://circleci.com/docs/ko/2.0/",
    "links.social.blogFeed": "https://circleci.com/blog/feed.xml",
    "links.social.facebook": "https://www.facebook.com/circleci",
    "links.social.twitter": "https://twitter.com/CircleCI",
    "links.social.linkedIn": "https://www.linkedin.com/company/circleci",
    "links.social.github": "https://github.com/circleci",
    "links.external.termsOfService": "https://circleci.com/terms-of-service/",
    "links.external.termsOfUse": "https://circleci.com/legal/terms-of-use/",
    "links.external.privacyPolicy": "https://circleci.com/legal/privacy/",
    "links.external.cookiePolicy": "https://circleci.com/legal/cookie-policy/",
    "links.external.security": "https://circleci.com/ko/security/",
    "links.external.login": "https://circleci.com/github-login/",
    "links.external.loginBitBucket": "https://circleci.com/bitbucket-login/",
    "links.external.authSignUp": "https://circleci.com/auth/signup/",
    "links.external.authLogin": "https://circleci.com/auth/login/",
    "links.external.loginPublic": "https://circleci.com/login-public/",
    "links.external.recaptchaTerms": "https://policies.google.com/terms?hl=ko",
    "links.external.recaptchaPrivacy": "https://policies.google.com/privacy?hl=ko",
    "vcsAuthorizePage.title": "로그 인 - CircleCI",
    "vcsAuthorizePage.metaTitle": "로그 인",
    "vcsAuthorizePage.metaDescription": "CircleCI 계정으로 로그인하십시오. 계정이 없는 경우 다음에서 등록하십시오. https://circleci.com/ko/signup/",
    "vcsAuthorizePage.metaDescriptionEnterprise": "Log into your CircleCI account",
    "signupPage.title": "가입 - CircleCI",
    "signupPage.metaTitle": "가입",
    "signupPage.metaDescription": "CircleCI에 가입하세요. 공개 및 개인 리포지토리는 무료로 구축을 시작할 수 있습니다. GitHub + Bitbucket과 연결하고, Linux, macOS, Windows, Android, Docker로 구축하세요.",
    "signupPage.canonicalLink": "https://circleci.com/ko/signup/"
  }
};
export default i18nTranslations;
